html,
body,
#app,
#root,
#app > div {
  height: 100% !important;
  width: 100%;
  overflow: auto;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  overscroll-behavior-y: contain;
}

.ReactVirtualized__Grid {
  outline: none;
}
